
.contentPanel {
  background: white;
  position: relative;
  word-break: break-word;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;
  >* {
    z-index: 1;
    position: relative;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .typing_title {
    letter-spacing: -1px;
    display: none;
    font-weight: 600;
  }
  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }
  a.button {
    text-align: center;
    font-size: 22px;
    color: black;
    background: transparent;
    font-weight: $weight-bold;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    border: 3px solid black;
    border-radius: 40px;
    &:focus, &:hover {
      color: white;
      background: $link-hover-color;
      text-decoration: none;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 1rem 0;
  }
}
.panel_typing_effect .typing_title {
  letter-spacing: -1px;
  word-break: normal;
  display: block;
  font-size: 44px;
  @include media-breakpoint-up(lg) {
    font-size: 60px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 88px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 88px;
    margin-left: 75px;
    margin-right: 75px;
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}

//override bootstrap container size
.container{
  @include media-breakpoint-up(xl){
      max-width: 1140px;
  }
}

#contact {
  a.button {
    color: #fff;
    background-color: transparent;
    border: 2px solid #f69b7e;
    &:hover{
      color: white;
      background-color: #f69b7e;
      border-color: #f69b7e;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actions {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: transparent;
      border: 2px solid #f69b7e;
      padding: 0.5rem 1.5rem;
      &:hover{
        color: white;
        background-color: #f69b7e;
        border-color: #f69b7e;
      }
    }
  }
  padding-bottom: 120px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 200px;
  }
}
// ---------------------------------------
.panel_typing_effect .container {
  padding-left: 40px;
  padding-right: 40px;
  @include media-breakpoint-up(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
// ---------------------------------------
.panel_why_uplisted>.container {
  padding-left: 25px;
  padding-right: 25px;
  @include media-breakpoint-up(lg) {
    padding-left: 45px;
    padding-right: 45px;
  }
}
// .panel_why_uplisted .container {
//   @include media-breakpoint-up(xxl) {
//     max-width: 55vw;
//   }
// }
.panel_why_uplisted h2 {
  // font-weight: 500;
  padding-left: 15px;
  @include media-breakpoint-up(xxl) {
    padding-left: 0;
    padding-right: 0;
  }
  // padding-left: 0;
  // padding-right: 0;
}
.panel_why_uplisted .col-12:nth-of-type(1) {
  @include media-breakpoint-up(xxl) {
    padding-left: 0;
    padding-right: 35px;
  }
}
.panel_why_uplisted .col-12:nth-of-type(2) {
  @include media-breakpoint-up(xxl) {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.panel_why_uplisted .col-12:nth-of-type(3) {
  @include media-breakpoint-up(xxl) {
    padding-left: 35px;
    padding-right: 0;
  }
}
// ---------------------------------------
.panel_how_it_works .container {
  padding-left: 40px;
  padding-right: 40px;
  @include media-breakpoint-up(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
// ---------------------------------------
.panel_subscribe>.container {
  padding-left: 40px;
  padding-right: 40px;
  @include media-breakpoint-up(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.panel_subscribe .container .container {
  padding-left: 0;
  padding-right: 0;
}
.panel_subscribe h2 {
  padding-left: 0;
  padding-right: 0;
}
.panel_subscribe .col-12:nth-of-type(1) {
  @include media-breakpoint-up(xxl) {
    padding-left: 0;
    padding-right: 35px;
  }
}
.panel_subscribe .col-12:nth-of-type(2) {
  @include media-breakpoint-up(xxl) {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.panel_subscribe .col-12:nth-of-type(3) {
  @include media-breakpoint-up(xxl) {
    padding-left: 35px;
    padding-right: 0;
  }
}
.panel_subscribe .contentPanel a.button {
  @media screen and (max-width: 992px) {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}
// ---------------------------------------
.panel_faq .container {
  padding-left: 40px;
  padding-right: 40px;
  @include media-breakpoint-up(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
