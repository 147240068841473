.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;
  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {
  .tile__bg{
    padding-top: 65%;
    position: relative;
  }
  .tile__line{
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .tile__line__plus{
    position: absolute;
    bottom: 97%;
    right: 0px;
    width: 18%;
    img{width: 100%;}
  }
  path{
    fill: #f69b7e;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    .pageTile__line__style{
      border-top-color: white;
    }
    .pageTile__title,
    .pageTile_desc {
      color: white !important;
    }
    path{
      fill: black;
    }
    .pageTile_details {
      background-color: black;
    }
  }
}

.pageTile__title {
  font-family: $family-base;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1;
  margin: 0.5rem 0;
  margin-bottom: 0px;
  padding-bottom: 0.5rem;
  color: black;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.8rem;
  color: #252525 !important;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.pageTile__line__style{
  width: 40%;
  margin-top: 0rem;
  padding-bottom: 0.5rem;
  border-top-color: black;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 0.5rem 0.8rem;
  padding-bottom: 2.5rem;
  text-align: center;
  background-color: #f69b7e;
  &:hover,
  &:focus {
    background-color: black;
  }
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

.pageTile.tile{
  padding-left: 15px;
  padding-right: 15px;
}