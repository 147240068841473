b,
strong {
  font-weight: $weight-bold;
}
a {
  color: $link-color;
  transition: $transition-base;
  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
    text-decoration: none;
  }
}

// font-weight
h1,
h2,
h3,
h4,
h5 {
  font-family: $family-base;
  color: $font-color;
  line-height: 1.2;
  margin-bottom: 0;
}
h1, h2, h3, h4, h5 {
  font-weight: $weight-light;
}
p {
  margin-bottom: 0;
}

// font-size
h1 {
  font-size: 88px;
  @media screen and (max-width: 576px) {
    font-size: 48px;
  }
}
h2 {
  font-size: 60px;
  @media screen and (max-width: 576px) {
    font-size: 40px;
  }
}
h3 {
  // font-size: 48px;
  font-size: 40px;
  @media screen and (max-width: 576px) {
    font-size: 32px;
  }
}
h4 {
  // font-size: 36px;
  font-size: 30px;
  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
}
h5 {
  font-size: 16px;
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}
.text-big {
  font-size: 1.5rem;
}