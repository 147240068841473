.bottomMenu {
  color: $primary-color;
  position: fixed;
  height: 80px;
  display: flex;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  padding-top: 10px;
  padding-bottom: 15px;
  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }
  ul {
    display: flex;
    margin: auto;
    max-width: 400px;
    @include media-breakpoint-up(lg) {
      max-width: 650px;
    }
  }
  li {
    flex: 1 1 20%;
    text-align: center;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 0;
  }
}


.bottomMenu__li {
  width: 78px;
  transition: all 0.5s ease-out;
  // &:hover, &:focus, &.selected {
  //   background-color: rgba(#000, 0.1);
  // }
}

.bottomMenu__links{
  gap: 0;
  grid-gap: 0;
  @include media-breakpoint-up(lg) {
    gap: 60px;
  }
}

.bottomMenu__link {
  display: inline-block;
  color: $primary-color;
  text-align: center;
  padding-right: 4px;
  padding-left: 4px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  opacity: 0.7;
  font-family: $family-base;
  * {
    position: relative;
    z-index: 100;
  }
  .icon {
    width: 18px;
    height: 18px;
    margin: auto;
    margin-bottom: 8px;
  }
  div {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.3rem;
  }
  &:hover,
  &:focus,
  &.selected {
    color: white;
    transition: none;
    text-decoration: none;
    opacity: 1;
  }
  @include media-breakpoint-up(lg) {
    padding: 15px 5px;
    .icon {
      margin-bottom: 15px;
    }
    div {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }
}
.bottomMenu__link--2 {
  &:focus {
    color: #f69b7e;
    opacity: .7;
  }
}
.icon {
  img { width: 100%; height: 100%; }
  .normal { display: block; opacity: 0.3; }
  .selected { display: none; }
}
.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {
   .normal { display: none; }
   .selected { 
      display: block; 
      transform: scale(1.2);
      transition: all .2s ease-in-out;
    }
}
.bottomMenu__links>.selected>.bottomMenu__link {
  color: white;
  opacity: 1;
}
.bottomMenu__links>.selected .icon>.normal {
  display: none;
}
.bottomMenu__links>.selected .icon>.selected {
  display: block;
}